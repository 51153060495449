import "./App.css";
// import gif from "https://mastershave.com/upload/MasterShave.gif";

const App = () => {
  return (
    <>
      <div className=" bg-[#29348E] min-h-screen">
        <img
          src="https://mastershave.com/upload/MasterShave.gif"
          className="w-full top-[15%] absolute"
        ></img>
      </div>
    </>
  );
};

export default App;
